import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import colors from '../constants/colors'

const sizes = {
    min: 0,
    tiny: 25,
    small: 50,
    medium: 100,
    large: 150,
}

const themes = {
    light: {
        color: '#000000',
        background: '#ffffff',
    },
    dark: {
        color: '#ffffff',
        background: '#000000',
    },
    grey: {
        color: '#000000',
        background: '#f5f5f5',
    },
    darkGrey: {
        color: '#ffffff',
        background: colors.darkGrey,
    },
    transparentLight: {
        color: '#ffffff',
        background: 'transparent',
    },
    transparentDark: {
        color: '#000000',
        background: 'transparent',
    },
    none: {
        color: 'inherit',
        background: 'transparent',
    },
}

const StyledSection = styled.section`
    position: relative;
    padding-top: ${(props) => props.size}px;
    padding-bottom: ${(props) => props.size}px;
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.color};
`

const Section = (props) => {
    const { theme, children, style, size, ...rest } = props
    const providedTheme = themes[theme]

    return (
        <ThemeProvider theme={providedTheme}>
            <StyledSection size={sizes[size]} style={style} {...rest}>
                {children}
            </StyledSection>
        </ThemeProvider>
    )
}

Section.defaultProps = {
    theme: 'light',
    size: 'medium',
}

export default Section
