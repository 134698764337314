import React, { useRef, useLayoutEffect, useState, useEffect } from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'
import { Container, Row, Column } from '../style/grid'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXProvider } from '@mdx-js/react'
import hexToHSL from '../utils/hexToHSL'
import { Line, Bar } from 'react-chartjs-2'
import Counter from '../components/counterComponent'
import Section from '../components/section'
import Parallax from '../components/parallax'
import Slider from '../components/sliderComponent'
import ResponsiveVideo from '../components/responsiveVideo'
import mediaQueries from '../constants/mediaQueries'

const StyledPortfolioAboveFold = styled.div`
    height: 100vh;
    position: relative;
    margin-top: 100px;
`

const StyledPortfolio = styled.div`
    p,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 30px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1em;
        word-wrap: break-word;
    }

    h1,
    h2 {
        margin-bottom: 50px;
        word-wrap: normal;
    }

    /**h1 {
        font-size: 3rem;
        font-weight: 800;
        letter-spacing: -0.05em;
        ${mediaQueries.md} {
            font-size: 6rem;
        }
    }

    h2 {
        font-size: 2rem;
        font-weight: 800;
        letter-spacing: -0.05em;
        ${mediaQueries.md} {
            font-size: 4rem;
        }
    }**/

    h1,
    h2 {
        font-size: 2rem;
        font-weight: 800;
        letter-spacing: -0.05em;
        ${mediaQueries.md} {
            font-size: 4rem;
        }
    }

    h3 {
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: -0.025em;
        ${mediaQueries.md} {
            font-size: 2rem;
        }
    }

    h4 {
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.1em;
    }

    h5 {
        font-size: 0.875rem;
        font-weight: 700;
        letter-spacing: 0.025em;
    }

    h6 {
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.025em;
    }
`

const StyledImgWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50vh;
    width: 100vw;
    overflow: hidden;

    .parallax {
        will-change: transform;
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
    }

    .parallax,
    .gatsby-image-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
`

const StyledPortfolioMeta = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: -100px;
    padding-top: 100px;
    height: 50vh;

    h1,
    h2 {
        line-height: 1;
    }

    h4 {
        text-transform: uppercase;
        letter-spacing: 2;
    }

    h5 {
        font-weight: 400;
        text-transform: lowercase;
    }

    ${Container} {
        flex: 1 1 auto;
    }
`

const ImgParallaxWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: Max(50vh, ${(props) => props.aspectRatio}%);
`

const Hero = ({ src, alt, aspectRatio }) => {
    const el = useRef(null)
    const wrapper = useRef(null)
    const [offset, setOffset] = useState(0)

    useLayoutEffect(() => {
        const onResize = () => {
            const elHeight = el.current.getBoundingClientRect().height
            const wrapperHeight = wrapper.current.getBoundingClientRect().height
            setOffset(elHeight > wrapperHeight ? elHeight - wrapperHeight : 0)
        }
        onResize()
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [el, wrapper])

    return (
        <StyledImgWrapper ref={wrapper}>
            <ImgParallaxWrapper ref={el} aspectRatio={aspectRatio}>
                <Parallax offset={offset} clampInitial initial={0}>
                    <GatsbyImage image={getImage(src)} alt={alt} />
                </Parallax>
            </ImgParallaxWrapper>
        </StyledImgWrapper>
    )
}

Hero.defaultProps = {
    aspectRatio: 100,
}

const bodyColor = (lightness) => (lightness > 50 ? 'black' : 'white')
const theme = (lightness) => (lightness > 50 ? 'light' : 'dark')

const PortfolioItem = ({ data: { mdx }, transitionStatus }) => {
    const { body, frontmatter } = mdx
    const img = frontmatter.image
    const background =
        typeof frontmatter.bgColor !== 'undefined'
            ? frontmatter.bgColor
            : '#000000'
    const lightness = hexToHSL(background)[2]

    useEffect(() => {
        document.body.style.overflowY = 'visible'
        document.documentElement.style.overflowY = 'visible'
    }, [])

    console.log(lightness)

    const shortcodes = {
        Section,
        Container,
        Row,
        Column,
        Line,
        Bar,
        Counter,
        ResponsiveVideo,
        Slider,
    }

    return (
        <Layout
            theme={theme(lightness)}
            title={mdx.frontmatter.seoTitle || mdx.frontmatter.title}
            image={img.childImageSharp.original.src}
            description={mdx.frontmatter.description}
        >
            <StyledPortfolio
                style={{
                    backgroundColor: background,
                    color: bodyColor(lightness),
                }}
            >
                <StyledPortfolioAboveFold>
                    <StyledPortfolioMeta>
                        <Container>
                            <h4>{frontmatter.category}</h4>
                            <h5>{frontmatter.tags.join(' / ')}</h5>
                            <h2>{mdx.frontmatter.title}</h2>
                        </Container>
                    </StyledPortfolioMeta>
                    <Hero
                        src={img}
                        alt={mdx.frontmatter.title}
                        aspectRatio={
                            (img.childImageSharp.original.height /
                                img.childImageSharp.original.width) *
                            100
                        }
                    />
                </StyledPortfolioAboveFold>

                <MDXProvider components={shortcodes}>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
            </StyledPortfolio>
        </Layout>
    )
}

export const pageQuery = graphql`
    query PortfolioItemQuery($id: String) {
        mdx(id: { eq: $id }) {
            body
            frontmatter {
                title
                tags
                category
                description
                seoTitle
                image {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                        )
                        original {
                            height
                            width
                            src
                        }
                    }
                }
                bgColor
            }
            id
        }
    }
`

export default PortfolioItem
