import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useDimensions from '../hooks/useDimensions'
import { Transition } from 'react-transition-group'

const StyledSlider = styled.div`
    overflow: hidden;
    position: relative;
    padding-bottom: ${(props) => props.aspectRatio * 100}%;
`

const Slide = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: transform 1s ease-in-out;

    p {
        margin-bottom: 0;
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
    }
`

const SliderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`

const StyledIndicators = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`

const StyledIndicatorContainer = styled.div`
    padding: 20px;
    cursor: pointer;
`

const StyledIndicator = styled.div`
    width: 10px;
    height: 10px;
    background: ${(props) =>
        props.dotColor ? props.dotColor : 'rgba(255,255,255,.5)'};
    border-radius: 50%;
    position: relative;
`

const StyledIndicatorHighlight = styled.div`
    top: -2px;
    left: -2px;
    background: ${(props) =>
        props.activeDotColor ? props.activeDotColor : '#ffffff'};
    border-radius: 50%;
    width: 14px;
    height: 14px;
    position: absolute;
    transition: all 0.8s;
`

const Pagination = ({ currentPage, setPage, images, options }) => {
    // Wrap all the pagination Indicators
    // with AnimateSharedPresence
    // so we can detect when Indicators
    // with a layoutId are removed/added
    return (
        <StyledIndicators>
            {images.map((img, index) => (
                <Indicator
                    key={index}
                    onClick={() => setPage(index)}
                    isSelected={index === currentPage}
                    options={options}
                />
            ))}
        </StyledIndicators>
    )
}

const Indicator = ({ isSelected, onClick, options }) => {
    return (
        <StyledIndicatorContainer onClick={onClick}>
            <StyledIndicator dotColor={options.dotColor}>
                {isSelected && (
                    // By setting layoutId, when this component
                    // is removed and a new one is added elsewhere,
                    // the new component will animate out from the old one.
                    <StyledIndicatorHighlight
                        activeDotColor={options.activeDotColor}
                    />
                )}
            </StyledIndicator>
        </StyledIndicatorContainer>
    )
}

const PageSlider = ({ currentPage, images }) => {
    const [trackRef, dimensions] = useDimensions()
    const xOffset = dimensions.width
    /* Add and remove pages from the array to checkout
     how the gestures and pagination animations are
     fully data and layout-driven. */
    const transitionStyles = {
        entering: {
            transform: `translateX(0px)`,
            visibility: 'visible',
        },
        entered: { transform: 'translateX(0px)' },
        exiting: { transform: `translateX(${xOffset}px)` },
        exited: {
            transform: `translateX(-${xOffset}px)`,
            visibility: 'hidden',
        },
    }

    return (
        <SliderContainer ref={trackRef}>
            {images.map((img, index) => (
                <Transition
                    in={index === currentPage}
                    appear={true}
                    timeout={1000}
                    key={index}
                >
                    {(state) => (
                        <Slide
                            data-page={index}
                            style={transitionStyles[state]}
                        >
                            {img}
                        </Slide>
                    )}
                </Transition>
            ))}
        </SliderContainer>
    )
}

const Slider = ({
    children,
    dotColor,
    activeDotColor,
    aspectRatio,
    interval,
}) => {
    const pagination = { dotColor: dotColor, activeDotColor: activeDotColor }

    console.log(interval)

    /* We keep track of the pagination direction as well as
     * current page, this way we can dynamically generate different
     * animations depending on the direction of travel */
    const [[currentPage, direction], setCurrentPage] = useState([0, 0])

    function setPage(newPage, newDirection) {
        if (!newDirection) newDirection = newPage - currentPage
        setCurrentPage([newPage, newDirection])
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentPage + 1 < children.length) {
                setPage(currentPage + 1, 1)
            } else {
                setPage(0, -1)
            }
        }, 5000)
        return () => clearTimeout(timer)
    }, [currentPage])

    return (
        <StyledSlider aspectRatio={aspectRatio}>
            <PageSlider
                currentPage={currentPage}
                direction={direction}
                setPage={setPage}
                images={children}
            />
            <Pagination
                currentPage={currentPage}
                setPage={setPage}
                images={children}
                options={pagination}
            />
        </StyledSlider>
    )
}

Slider.defaultProps = {
    dotColor: 'rgba(255,255,255,.5)',
    activeDotColor: '#ffffff',
    aspectRatio: 0.5,
    interval: 5000,
}

export default Slider
