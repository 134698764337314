import styled from 'styled-components'
import mediaQueries from '../constants/mediaQueries'
import breakpoints from '../constants/breakpoints'

const getColumnWidth = (number) => (parseInt(number) / 60) * 100 + '%'

function makeColumn(breakpoint, columnCount) {
    const columnWidth = getColumnWidth(columnCount)

    return `
		${mediaQueries[breakpoint]} {
			width: ${columnWidth}
		}
	`
}

export const Container = styled.div`
    max-width: ${(props) => (props.fluid ? '100%' : `${1100}px`)};
    padding-right: ${20}px;
    padding-left: ${20}px;
    margin-left: auto;
    margin-right: auto;
`

export const Row = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-right: -${20}px;
    margin-left: -${20}px;
    justify-content: ${(props) => (props.alignX ? props.alignX : 'flex-start')};
    align-items: ${(props) => (props.alignY ? props.alignY : 'flex-start')};

    ${(props) =>
        props.reverse &&
        `
      
        ${mediaQueries.sm} {
            flex-direction: row-reverse;
        }

    `}
`

export const Column = styled.div`
    padding-right: ${20}px;
    padding-left: ${20}px;
    min-height: 1px;
    position: relative;
    width: ${(props) => (props.xs ? getColumnWidth(props.xs) : `100%`)};

    ${(props) =>
        Object.keys(props)
            .filter((p) => Object.keys(breakpoints).includes(p))
            // Make column with breakpoint & column count params
            .map((p) => makeColumn(p, props[p]))}
`
