import React, {
    useState,
    useRef,
    useLayoutEffect,
    ReactNode,
    useEffect,
} from 'react'
import anime from 'animejs/lib/anime.es'
import { interpolate } from 'popmotion'

// https://samuelkraft.com/blog/spring-parallax-framer-motion-guide

const Parallax = ({
    children,
    offset = 50,
    clampInitial,
    clampFinal,
    initial,
    final,
}) => {
    const [elementTop, setElementTop] = useState(0)
    const [clientHeight, setClientHeight] = useState(0)
    const ref = useRef(null)
    const start =
        typeof initial !== 'undefined' ? initial : elementTop - clientHeight
    const finish = typeof final !== 'undefined' ? final : elementTop + offset

    const input = [start, finish]

    const output = [clampInitial ? 0 : offset, clampFinal ? 0 : -offset]

    const parallaxScroll = (y) => {
        const getVal = interpolate(input, output)
        const val = getVal(y)
        const element = ref.current
        anime({
            targets: element,
            easing: 'linear',
            translateY: val,
        })
    }

    useLayoutEffect(() => {
        const element = ref.current
        const onResize = () => {
            setElementTop(
                element.getBoundingClientRect().top + window.scrollY ||
                    window.pageYOffset
            )
            setClientHeight(window.innerHeight)
        }
        onResize()
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [ref])

    useEffect(() => {
        function onScroll(evt) {
            let scheduledAnimationFrame
            // Store the scroll value for laterz.
            function cb() {
                parallaxScroll(window.scrollY)
            }

            // Prevent multiple rAF callbacks.
            if (scheduledAnimationFrame) return

            scheduledAnimationFrame = true
            requestAnimationFrame(cb)
        }

        window.addEventListener('scroll', onScroll, false)

        return () => {
            window.removeEventListener('scroll', onScroll, false)
        }
    }, [input, output])

    return (
        <div ref={ref} className="parallax">
            {children}
        </div>
    )
}

export default Parallax
