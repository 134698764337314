import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import anime from 'animejs/lib/anime.es'

const sizes = {
    small: '1em',
    medium: '2.5em',
    large: '5em',
}

const StyledCounterDigit = styled.div`
    font-weight: 900;
    line-height: 1.1;
    word-wrap: break-word;
    font-size: ${(props) => sizes[props.size]};
    line-height: 1;
    margin: 0;
`

// https://codepen.io/brianacamp/pen/YRyrEV

function CounterDigit({ from, to, inView, duration, round }) {
    const ref = useRef()

    useEffect(() => {
        if (inView) {
            anime({
                targets: ref.current,
                textContent: to,
                easing: 'easeInOutQuad',
                duration: duration,
                round: round,
            })
        }
    }, [from, to, inView])

    return <span ref={ref}>{from}</span>
}

const Counter = ({
    start,
    digit,
    size,
    textBefore,
    textAfter,
    style,
    round,
    duration,
}) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    })

    return (
        <StyledCounterDigit ref={inViewRef} size={size} style={style}>
            <span>{textBefore}</span>
            <CounterDigit
                from={start}
                to={digit}
                inView={inView}
                duration={duration}
                round={round}
            />
            <span>{textAfter}</span>
        </StyledCounterDigit>
    )
}

Counter.defaultProps = {
    start: 0,
    digit: 100,
    duration: 1500,
    round: 1,
    size: 'medium',
    textBefore: '',
    textAfter: '',
}

export default Counter
