import React from 'react'
import styled from 'styled-components'

const StyledSlider = styled.div`
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: ${(props) =>
        props.aspectRatio ? props.aspectRatio : 56.2}%;
`

const StyledSlide = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    iframe,
    .react-player__preview {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    video {
        position: absolute;
        top: 0px;
        left: 0px;
        object-fit: cover;
        object-position: center center;
    }
`

const ResponsiveVideo = ({
    src,
    aspectRatio,
    playing,
    loop,
    muted,
    playsinline,
    style,
}) => {
    return (
        <StyledSlider aspectRatio={aspectRatio} style={style}>
            <StyledSlide>
                <video
                    src={src}
                    autoPlay={playing}
                    loop={loop}
                    muted={muted}
                    playsInline={playsinline}
                    width="100%"
                    height="100%"
                    style={{ height: `100%`, width: `100%` }}
                ></video>
            </StyledSlide>
        </StyledSlider>
    )
}

ResponsiveVideo.defaultProps = {
    aspectRatio: 56.2,
    playing: true,
    loop: true,
    muted: true,
    playsinline: true,
    style: {},
}

export default ResponsiveVideo
